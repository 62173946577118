import { GqlProductAttributeNodeInterface } from "@/lib/types/gql/product/product-by-slug.type";
import { BodyText } from "@/components/BodyText";
import { useEffect, useState } from "react";
import { Skeleton } from "../ui/skeleton";
import { ChangeValueType } from "./information";

type Props = {
  changeValue: (params: ChangeValueType) => void;
} & GqlProductAttributeNodeInterface;

export default function ProductAttributes(props: Props) {
  const label = props.label.toUpperCase();
  const [itemValue, setItemValue] = useState<string>("");
  const handleOnchange = (value: string) => {
    setItemValue(value);
  };

  useEffect(() => {
    itemValue &&
      props?.changeValue({
        key: props.name,
        value: itemValue,
        label: props.label,
      });
  }, [itemValue]);
  if (!props.visible) return null;
  return (
    <div className="select-none">
      {props ? (
        <BodyText className="font-semibold" size="md">
          {label}
        </BodyText>
      ) : (
        <Skeleton className="w-[120px] h-[20px]" />
      )}
      <div className=" flex flex-wrap items-center gap-2.5">
        {props.options.map((item: any, index: number) => (
          <div
            key={index}
            className="cursor-pointer"
            onClick={() => {
              handleOnchange(item);
            }}
          >
            <BodyText
              size="xs"
              className={`mt-1 ${
                itemValue == item ? "!bg-main text-white border-main" : "text-themeGray"
              } font-SGL cursor-pointer bg-white rounded-sm border-[1px] py-2 px-4`}
            >
              {item}
            </BodyText>
          </div>
        ))}
      </div>
    </div>
  );
}