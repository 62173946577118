"use client";

import clsx from "clsx";
import { StockStatus } from "@/lib/types/gql/product/product-by-slug.type";
import { useCart } from "@/lib/hooks/cart/useCart";
import { toast } from "sonner";
import { useEffect, useState } from "react";
import { RingLoader } from "react-spinners";
import { useUserStore } from "@/store/user.store";
import { useRouter } from "next/navigation";
import { isManagesStock } from "@/lib/utils";

export type ProductTypeName = "SimpleProduct" | "VariableProduct";

type Attribute = {
  variation: boolean | undefined; // 如果是单个产品这个一定要传入undefined
  visible: boolean; // 后台设置是否显示这个属性 如果为false 那么这个属性不可添加
  label: string;
  name: string; // 属性名称
  options: string[]; // 属性值
};

type SimpleAndVariable = {
  databaseId: string; // 产品id
  quantity: string; // 加入购物车的数量
  stockStatus: StockStatus; // 库存状态
  stockQuantity?: number | null; // 库存数量 库存数量不能小于quantity
  attributes: Attribute[]; // 产品原始数据的选项 用于添加购物车前端逻辑校验
};

type Simple = SimpleAndVariable & {
  // 单个产品需要传入的数据
  item_data?: Record<string, string>; // 选中的产品属性 键值对 如果原始数据产品没有属性可以不传
};

type Variable = SimpleAndVariable & {
  // 变体产品需要传入得数据
  variation: {
    // 对应选中得databaseId的attributes
    label: string;
    name: string;
    value: string;
  }[];
};

type ProductType = {
  SimpleProduct: Simple;
  VariableProduct: Variable;
};

type AddToCartButtonProps<T extends ProductTypeName> = {
  className?: string;
  disabled?: boolean;
  __typename: T;
  buyNow?: boolean;
  product: ProductType[T];
  loadingColor?: string;
};

const quantityVerification = (quantity: number, stock: StockStatus, stockQuantity?: number | null) => {
  // 如果添加的数量小于1
  if (quantity < 1) {
    return "The quantity cannot be less than 1";
  }

  // 未开启库存管理
  if (!isManagesStock(stock, stockQuantity)) {
    return true;
  }
  // 如果添加的数量大于库存数量
  if (quantity > (stockQuantity || 0)) {
    return "Quantity exceeds inventory";
  }
  // 如果通过
  return true;
};

// 校验单个产品的item_data和attributes是否匹配
const itemDataVerification = (
  item_data: Record<string, string> | undefined,
  attributes: Attribute[]
): true | string => {
  let flag: true | string = true;
  if (!item_data) {
    flag = "item_data is required";
    throw new Error("item_data 是必需的");
  }
  try {
    // 遍历item_data
    Object.keys(item_data).map((k) => {
      // 判断k是否存在attributes的name
      const atr = attributes.find((a) => a.name === k);
      // 如果存在判断这个属性是否设置了在前端显示
      if (!atr || !atr.visible) {
        flag = "non existent key";
        throw new Error("不存在的key");
      }
      // 判断value是否存在
      if (!atr.options.includes(item_data[k])) {
        flag = "non existent value";
        throw new Error("不存在的value");
      }
    });
  } catch (e: any) {
    console.error(e);
  }
  return flag;
};

// 校验可变产品的variation是否匹配
const variationVerification = (variation: Variable["variation"], attributes: Attribute[]): true | string => {
  let flag: true | string = true;
  try {
    variation.map((item) => {
      const { name, value } = item;
      console.log(attributes);
      console.log(name);
      
      // 找到对应的属性
      // const atr = attributes.find((a) => a.name.toLowerCase() === name.toLowerCase());
      // 处理 name 和 attributes 中 name 的对比，去除空格、横杠等
      const cleanName = name.toLowerCase().replace(/[-_\s]+/g, '');  // 去除横杠、下划线和空格
      const atr = attributes.find((a) => a.name.toLowerCase().replace(/[-_\s]+/g, '') === cleanName);
      console.log(atr);
      
      // 如果找不到说明key错误 或者 判断是否显示变量
      if (!atr || !atr.visible) {
        flag = "non existent key";
        return;
      }
      // 找到之后判断value是否是该属性中
      if (!atr?.options.includes(value)) {
        flag = "non existent value";
        return;
      }
      // 都存在判断是否是否用于变体
      if (!atr?.variation) {
        flag = "Invalid variant";
        return;
      }
      flag = true;
      if (flag === true) {
        throw new Error("variationVerification");
      }
    });
  } catch (e) {
    // console.error(e);
  }
  return flag;
};

// 校验所有参数
const verification = <T extends ProductTypeName>(props: AddToCartButtonProps<T>): true | string => {
  let flag: string | true = true;
  // 校验数量和库存
  const quantityFlag = quantityVerification(
    +props.product.quantity,
    props.product.stockStatus,
    props.product.stockQuantity
  );
  if (quantityFlag !== true) return quantityFlag;

  if (props.__typename === "SimpleProduct") {
    // 校验item_data 是否 与 attributes 匹配
    const simple = props.product as Simple;
    const itemDataFlag = itemDataVerification(simple?.item_data, simple.attributes);
    // 不匹配则提示错误
    if (itemDataFlag !== true) {
      flag = itemDataFlag;
    }
  } else {
    // 校验variation 是否与 attributes 匹配
    const variable = props.product as Variable;
    const variableFlag = variationVerification(variable.variation, variable.attributes);
    // 不匹配则提示错误
    if (variableFlag !== true) {
      flag = variableFlag;
    }
  }
  return flag;
};

export const AddToCartButton = <T extends ProductTypeName>(props: AddToCartButtonProps<T>) => {
  const productType = props.__typename === "SimpleProduct" ? 1 : 2; // 1是单个产品 2是可变产品
  const [disabled, setDisabled] = useState(!!props?.disabled);
  const [loading, setLoading] = useState(false);
  const { userInfo, updateLoginModal } = useUserStore();
  const { addToCart } = useCart();
  const router = useRouter();
  const handleClick = async (type: 1 | 2) => {
    if (!userInfo) {
      updateLoginModal(true);
      toast.warning("Please log in first!");
      return;
    }
    if (disabled || loading) return;
    const flag = verification<T>(props); // 校验是否通过
    if (flag !== true) return toast.error(flag);

    let res;
    if (productType === 1) {
      const simple = props.product as Simple;
      const where = {
        id: simple.databaseId.toString(),
        quantity: simple.quantity,
        item_data: simple?.item_data || undefined,
      };
      res = await addToCart(where, setLoading);
    } else {
      const variable = props.product as Variable;
      const variation: Record<string, string> = {};
      variable.variation.map((item) => {
        variation["attribute_" + item.name] = item.value;
      });
      const where = {
        id: variable.databaseId.toString(),
        quantity: variable.quantity,
        variation,
      };
      res = await addToCart(where, setLoading);
    }
    
    // 将重复的跳转逻辑提取到条件分支外部
    if (props?.buyNow && type === 2) {
      await router.push("/checkout");
    }
  };

  useEffect(() => {
    setDisabled(!!props?.disabled);
  }, [props]);

  return (
    <div className="s-flex gap-x-4 w-full">
      {props?.buyNow ? (
        <div
          onClick={() => {
            handleClick(2);
          }}
          className={clsx(
            "px-3 py-2 c-flex gap-4 rounded-[5px] inline-block text-white bg-main cursor-pointer duration-300",
            props?.className,
            disabled || loading ? "!cursor-not-allowed opacity-50" : "hover:opacity-90"
          )}
        >
          {loading && <RingLoader color={"#fff"} size={20} className="mr-1" />}
          <span className="whitespace-nowrap">Buy Now</span>
        </div>
      ) : (
        <div
          onClick={() => {
            handleClick(1);
          }}
          className={clsx(
            "px-3 py-2 c-flex gap-4 rounded-[5px] inline-block text-white bg-main cursor-pointer duration-300",
            props?.className,
            disabled || loading ? "!cursor-not-allowed opacity-50" : "hover:opacity-90"
          )}
        >
          {loading && <RingLoader color={props?.loadingColor ?? "#fff"} size={20} className="mr-1" />}
          <span className="whitespace-nowrap ">Add To Cart</span>
        </div>
      )}
    </div>
  );
};
